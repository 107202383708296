<div [class.bg-light]="scrolled" class="pb-3  no-print">
  <nav class="container mt-0 pt-3 d-flex justify-content-between">
    <a href="#" [routerLink]="['/']">


      <!-- desktop             -->

      @if (!scrolled) {
        <img src="..\..\..\assets\images\rentability-logo2.png" width="166" height="35" alt="Rentability"
          class="d-sm-none d-md-block d-none d-sm-block me-5" />
      }
      @if (scrolled) {
        <img src="..\..\..\assets\images\rentability-logo.png" width="166" height="35" alt="Rentability"
          class="d-sm-none d-md-block d-none d-sm-block me-5" />
      }

      <!-- mobile          -->
      @if (scrolled) {
        <img src="..\..\..\assets\images\rentability-logo.png" class="d-block d-sm-block d-md-none me-1" alt="Rentability"
          width="84" height="17" />
      }
      @if (!scrolled) {
        <img src="..\..\..\assets\images\rentability-logo2.png" class="d-block d-sm-block d-md-none me-1" alt="Rentability"
          width="84" height="17" />
      }


    </a>
    <app-search-box style="width:100%" (changed)="onAddressChanged($event)"></app-search-box>
    <app-header-menu></app-header-menu>
  </nav>
</div>