/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApplicationService } from './services/application.service';
import { DataService } from './services/data.service';
import { FavoriteService } from './services/favorite.service';
import { GeoService } from './services/geo.service';
import { ListingService } from './services/listing.service';
import { MessageService } from './services/message.service';
import { PlanService } from './services/plan.service';
import { ProfileService } from './services/profile.service';
import { ReviewService } from './services/review.service';
import { SavedSearchService } from './services/saved-search.service';
import { WalkthroughService } from './services/walkthrough.service';
import { AccountsService } from './services/accounts.service';
import { AdminService } from './services/admin.service';
import { CouponsService } from './services/coupons.service';
import { EventService } from './services/event.service';
import { NotificationService } from './services/notification.service';
import { PaymentService } from './services/payment.service';
import { ReferralCodesService } from './services/referral-codes.service';
import { ServerSideListingService } from './services/server-side-listing.service';
import { TenantService } from './services/tenant.service';
import { TextContentService } from './services/text-content.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApplicationService,
    DataService,
    FavoriteService,
    GeoService,
    ListingService,
    MessageService,
    PlanService,
    ProfileService,
    ReviewService,
    SavedSearchService,
    WalkthroughService,
    AccountsService,
    AdminService,
    CouponsService,
    EventService,
    NotificationService,
    PaymentService,
    ReferralCodesService,
    ServerSideListingService,
    TenantService,
    TextContentService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
