import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withFetch, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ApiModule } from './app/api/api.module';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { SessionService } from './app/core/services/session.service';
import { DateInterceptor, LogInterceptor } from './app/shared/interceptors';
import { tokenInterceptor } from './app/shared/interceptors/token.interceptor';
import { errorInterceptor } from './app/shared/services/error-handler.service';
import { LoggingService } from './app/shared/services/logging.service';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

export function tokenGetter() {
    return localStorage.getItem("access_token");
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(AppRoutingModule,
            JwtModule.forRoot({
                config: {
                    tokenGetter: tokenGetter,
                    allowedDomains: [environment.api],
                    disallowedRoutes: [],
                }
            }),
            TranslateModule.forRoot({}),
            BsDropdownModule.forRoot(),
            ApiModule.forRoot({
                rootUrl: environment.api,
            }), TypeaheadModule.forRoot()),
        HttpClient,
        SessionService,
        //{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        LoggingService,
        provideHttpClient(
            withInterceptors([errorInterceptor, tokenInterceptor]),
            withFetch()
        ),
        { provide: HTTP_INTERCEPTORS, useClass: DateInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LogInterceptor, multi: true },
        //{ provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
        provideHttpClient(withInterceptorsFromDi()),
        provideAnimations()
    ]
})
    .catch(err => console.error(err));
