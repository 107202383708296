<ng-template #customItemTemplate let-model="item" let-index="index">
    <fa-icon [icon]="faMapMarkerAlt" class="me-1"></fa-icon><span>{{model}}</span>
</ng-template>

<input type="text" class="form-control rounded-pill p-3 shadow-sm" style="width:100%" [placeholder]="placeholder"
    [(ngModel)]="text" [typeahead]="locations$" [typeaheadItemTemplate]="customItemTemplate" [typeaheadAsync]="true"
    (typeaheadOnSelect)="onLocationChanged($event)" />
<button type="button" class="btn rounded-circle bg-bright-gradient p-3" (click)="onSearch()">
    <fa-icon [icon]="icon" class="icon"></fa-icon>
</button>


<!-- <ng-select [(ngModel)]="location" [items]="locations | async" [typeahead]="locationInput" (focus)="onFocus($event)"
           title="{{location}}" (change)="onLocationChanged($event)" [class.big]="big"
           [minTermLength]="2" typeToSearchText="Please enter 2 or more characters" [placeholder]="placeholder" class="custom-select">
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <fa-icon [icon]="faMapMarkerAlt" class="me-1"></fa-icon><span>{{item}}</span> 
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    <fa-icon [icon]="faSearch" class="me-1 text-muted"></fa-icon>{{item}}
  </ng-template>
</ng-select> -->