import { Component, DestroyRef, ElementRef, Renderer2, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderComponent } from './core/header/header.component';
import { ModeToggleComponent } from './core/mode-toggle/mode-toggle.component';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, FooterComponent, ModeToggleComponent]
})
export class AppComponent {
private renderer = inject(Renderer2);
private readonly elementRef = inject(ElementRef);
private route = inject(ActivatedRoute);

show=false;
  isIframe = false;
  title = 'Rentability';
  env = environment;
  destroyRef = inject(DestroyRef);

  constructor() {
    this.isIframe = window !== window.parent && !window.opener; // Remove this line to use Angular Universal

    

    if (this.env.isHubspotEnabled) {
      this.attachHubspotScript();
    }

    this.route.queryParams.pipe(
      tap(params => {
        if (params['error'])
          alert(params['error_description']);
      }),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe();
  }

  private attachHubspotScript() {
    const script = this.renderer.createElement('script');
    script.src = 'https://js.hs-scripts.com/8183142.js';
    script.type = 'text/javascript';
    script.async = true;
    script.defer = true;
    this.renderer.appendChild(this.elementRef.nativeElement, script);
  }

}
