<div class="sticky-top">
  <app-header></app-header>
</div>
<div class="app-body">
  @if (!isIframe) {
    <router-outlet></router-outlet>
  }
</div>

<app-footer></app-footer>
