import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './core/error/error.component';
import { landlordGuard, salesGuard } from './shared/guards';
import { adminGuard } from './shared/guards/admin.guard';
import { authGuard } from './shared/guards/auth.guard';
 
const routes: Routes = [

  // Account
  { path: 'register', loadComponent: () => import('./account/registration/registration.component').then(m => m.RegistrationComponent) },
  { path: 'registration-complete', loadComponent: () => import('./account/registration-complete/registration-complete.component').then(m => m.RegistrationCompleteComponent) },
  { path: 'two-factor', loadComponent: () => import('./account/two-factor/two-factor.component').then(m => m.TwoFactorComponent) },
  { path: 'login', loadComponent: () => import('./account/login/login.component').then(m => m.LoginComponent) },
  { path: 'logout', loadComponent: () => import('./account/logout/logout.component').then(m => m.LogoutComponent) },
  { path: 'forgot-password', loadComponent: () => import('./account/forgot-password/forgot-password.component').then(m => m.ForgotPasswordComponent) },
  { path: 'reset-password', loadComponent: () => import('./account/reset-password/reset-password.component').then(m => m.ResetPasswordComponent) },
  { path: 'confirm-email', loadComponent: () => import('./account/confirm-email/confirm-email.component').then(m => m.ConfirmEmailComponent) },
  { path: 'r/:id', loadComponent: () => import('./refer/refer.component').then(m => m.ReferComponent) },
  { path: 'success', loadComponent: () => import('./success/success.component').then(m => m.SuccessComponent), canActivate: [authGuard]},

  // Admin
  { path: 'users', loadComponent: () => import('./admin/users/users.component').then(m => m.UsersComponent), canActivate: [authGuard, adminGuard] },
  
  { path: 'plans', loadComponent: () => import('./admin/plans/plans.component').then(m => m.PlansComponent), canActivate: [authGuard, adminGuard] },
  { path: 'roles', loadComponent: () => import('./admin/roles/roles.component').then(m => m.RolesComponent), canActivate: [authGuard, adminGuard] },
  { path: 'subscriptions', loadComponent: () => import('./admin/subscriptions/subscriptions.component').then(m => m.SubscriptionsComponent), canActivate: [authGuard, adminGuard] },

  // Plans and Pricing
  { path: 'pricing', loadComponent: () => import('./pricing/pricing.component').then(m => m.PricingComponent) },
  { path: 'upgrade', loadComponent: () => import('./upgrade-page/upgrade-page.component').then(m => m.UpgradePageComponent) },

  //Sales
  { path: 'payments', loadComponent: () => import('./payments/payments.component').then(m => m.PaymentsComponent), canActivate: [authGuard, salesGuard] },
  { path: 'refer', loadComponent: () => import('./sales/referral-codes/referral-codes.component').then(m => m.ReferralCodesComponent), canActivate: [authGuard, salesGuard] },
  { path: 'coupons', loadComponent: () => import('./sales/coupons/coupons.component').then(m => m.CouponsComponent), canActivate: [authGuard, salesGuard] },

  // Landlord
  { path: 'listings', loadComponent: () => import('./listings/listings.component').then(m => m.ListingsComponent), canActivate: [authGuard, landlordGuard] },
  { path: 'add', loadComponent: () => import('./listing/listing-edit/edit.component').then(m => m.EditComponent), canActivate: [authGuard] },

  // User
  { path: 'search', loadComponent: () => import('./search/search/search.component').then(m => m.SearchComponent) },
  { path: 'profile/:id', loadComponent: () => import('./profile-view/profile-view.component').then(m => m.ProfileViewComponent), canActivate: [authGuard] },
  { path: 'me', loadComponent: () => import('./profile/me.component').then(m => m.MeComponent), canActivate: [authGuard] },
  { path: 'listing', loadChildren: () => import('./listing/listing.module').then(m => m.ListingModule) },
  { path: 'dashboard', loadComponent: () => import('./dashboard/dashboard.component').then(m => m.DashboardComponent), canActivate: [authGuard] },
  { path: 'applications', loadComponent: () => import('./applications/applications.component').then(m => m.ApplicationsComponent), canActivate: [authGuard] },
  { path: 'messages', loadComponent: () => import('./messages/message-main/message-main.component').then(m => m.MessageMainComponent), canActivate: [authGuard] },
  { path: 'resources', loadComponent: () => import('./resources/resources.component').then(m => m.ResourcesComponent), canActivate: [authGuard] },
  { path: 'favorites', redirectTo: 'favourites' },
  { path: 'favourites', loadComponent: () => import('./favourites/favorites.component').then(m => m.FavoritesComponent), canActivate: [authGuard] },
  { path: 'notifications', loadComponent: () => import('./notifications/notifications.component').then(m => m.NotificationsComponent), canActivate: [authGuard] },

  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: '**', component: ErrorComponent, data: { message: 'Page Not Found' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
