<div class="border-top no-print">
  <div class="container pt-2">
    <div class="row text-small mb-5">
      <div class="col-md-4 col-sm-12 d-flex justify-content-center">
        <img src="..\..\..\assets\images\rentability-logo.png" title="Rentability" />
      </div>
      <div class="col">
        <app-contact-us></app-contact-us>
      </div>
      <div class="col">
        <app-help-menu></app-help-menu>
      </div>
      @if (false) {
        <div class="col-md-4 col-sm-6">
          <app-email-signup></app-email-signup>
        </div>
      }
    </div>
    <div class="d-flex justify-content-between text-tiny mt-5">
      <div>&copy; {{year}} Rentability Inc.</div>
      <div class="d-flex justify-content-between">
        <a href="#" [routerLink]="['/home/terms-of-sale']" class="me-2">Terms of Sale</a>
        <a href="#" [routerLink]="['/home/terms-of-use']" class="me-2">Terms of Use</a>
        <a href="#" [routerLink]="['/home/privacy-policy']">Privacy Policy</a>
        <span class="ms-1">v{{version}}</span>
      </div>
    </div>
  </div>
</div>