import { HttpEvent, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, catchError, switchMap, throwError } from 'rxjs';
import { AuthService } from '../services/auth-service.service';

export const tokenInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {

  const authService = inject(AuthService);
  const accessToken = authService.getAccessToken();
  if (!!accessToken) {
    const clonedRequest = request.clone({
      setHeaders: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return next(clonedRequest).pipe(
      catchError(error => {
        if (error?.status === 400) {
          return authService.doRefresh().pipe(switchMap(res => {
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${authService.getAccessToken()}`,
              },
            });
            return next(request);
          }));

        }
        return throwError(() => error);
      })
    );
  }
  return next(request);
};
