import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { DataService } from 'src/app/api/services';
import { AlertService } from '../services';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-email-signup',
    templateUrl: './email-signup.component.html',
    styleUrls: ['./email-signup.component.scss'],
    standalone: true,
    imports: [FormsModule]
})
export class EmailSignupComponent implements OnInit, OnDestroy {
  private dataService = inject(DataService);
  private alertService = inject(AlertService);


  stop$ = new Subject<void>();
  email = '';

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.stop$.next();
    this.stop$.complete();
  }

  onSignUp() {
    this.dataService.signup({ email: this.email }).pipe(
      takeUntil(this.stop$),
      tap(() => {
        this.alertService.toastSuccess('Subscription created...');
        this.email = '';
      })
    ).subscribe();
  }
}
