import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class DateInterceptor implements HttpInterceptor {
    constructor() { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
                event = event.clone({ body: this.parseObject(event.body) });
            }
            return event;
        }));
    }

    parseObject(obj: any) {
        if (obj) {
            Object.keys(obj).forEach((key, index) => {
                const value = obj[key];
                if (typeof value === 'object' && value !== null) {
                    obj[key] = this.parseObject(value);
                } else {
                    if (typeof value === 'string') {
                        const dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
                        const dateTimeFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;

                        if (dateFormat.test(value) || dateTimeFormat.test(value)) {
                            obj[key] = new Date(value);
                        }
                    }
                }
            });
        }
        return obj;
    }
}
