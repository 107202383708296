import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  private route = inject(ActivatedRoute);


  message?:string;

  ngOnInit() {
    this.message = this.route.snapshot.data['message']
  }

}
