import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, Renderer2, inject } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faEclipse } from '@fortawesome/fontawesome-pro-duotone';
import { TinyMceConfig } from 'src/app/shared/common';

@Component({
  selector: 'app-mode-toggle',
  standalone: true,
  imports: [FontAwesomeModule],
  templateUrl: './mode-toggle.component.html',
  styleUrl: './mode-toggle.component.scss'
})
export class ModeToggleComponent implements OnInit {
  private document = inject(DOCUMENT);
  private renderer = inject(Renderer2);

  faEclipse = faEclipse;
  shown = false;

  @Output() changeTheme = new EventEmitter<string>();

  ngOnInit(): void {
    const isDark = localStorage.getItem('theme') === 'dark';
    if (isDark) {
      this.renderer.setAttribute(document.querySelector('body'), 'data-bs-theme', isDark ? 'dark' : 'light');
    }
    this.changeTheme.emit(isDark ? 'dark' : 'light');
  }

  toggleTheme() {
    const isDark = document.querySelector('body')?.getAttribute('data-bs-theme') === 'dark';
    this.renderer.setAttribute(document.querySelector('body'), 'data-bs-theme', !isDark ? 'dark' : 'light');
    localStorage.setItem('theme', !isDark ? 'dark' : 'light');
    TinyMceConfig.skin = !isDark ? 'oxide-dark' : 'oxide';
    TinyMceConfig.content_css = !isDark ? ['dark', 'https://fonts.googleapis.com/css?family=Montserrat|Open+Sans'] : ['https://fonts.googleapis.com/css?family=Montserrat|Open+Sans'];
    this.changeTheme.emit(!isDark ? 'dark' : 'light');
  }

}
