import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RouterLink } from '@angular/router';
import { EmailSignupComponent } from '../email-signup/email-signup.component';

import { HelpMenuComponent } from '../help-menu/help-menu.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [ContactUsComponent, HelpMenuComponent, EmailSignupComponent, RouterLink]
})
export class FooterComponent implements OnInit {

  environment = environment;

  constructor() { }

get version():string{
  return this.environment.version;
}

get year():number{
  const date = new Date();
  return date.getFullYear();
}

  ngOnInit(): void {
  }

}
